<template>
  <div id="app">
    <div id="pragmatic">
      <div class="ManageTop">
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          基本信息
        </div>
        <div class="pt-3">
          <div class="ManageTopInput">
            <div class="box align-items-center" style="width: 30%">
              <span class="TitleText">企业名称 </span>
              <div class="grid-content bg-purple ml-2">{{ name }}</div>
            </div>
            <div class="box align-items-center" style="width: 30%">
              <span class="TitleText">附注表类型 </span>
              <el-select
                :disabled="flag !== '0'"
                v-model="codetype"
                size="small"
                style="width: 300px"
              >
                <el-option
                  v-for="item in pageType"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
            <div class="box align-items-center" style="width: 30%">
              <span class="TitleText">报表类型 </span>
              <el-select
                :disabled="flag !== '0'"
                v-model="BType"
                size="small"
                style="width: 300px"
              >
                <el-option
                  v-for="item in pageTypeList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
            <div class="box mt-4 align-items-center" style="width: 30%">
              <span class="TitleText">报表日期 </span>
              <el-date-picker
                :disabled="flag !== '0'"
                size="small"
                v-model="dateTime"
                :type="BType == '00' ? 'year' : 'month'"
                :popper-append-to-body="false"
                style="width: 300px"
              >
              </el-date-picker>
            </div>
            <div class="box mt-4 align-items-center" style="width: 30%">
              <span class="TitleText">报表种类 </span>
              <el-select
                :disabled="flag !== '0'"
                v-model="code"
                size="small"
                style="width: 300px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="ManageBottom" style="min-height: 60vh">
        <div style="justify-content: space-between" class="row">
          <div class="row align-items-center">
            <div class="sp"></div>
            <div class="ml-2 mr-2 lsp" v-show="codetype == 's1'">
              授信辅助表
            </div>
            <div class="ml-2 mr-2 lsp" v-show="codetype == 's2'">
              补充数据表
            </div>
            <div class="ml-2 mr-2 lsp" v-show="codetype == 's3'">
              应收账款及应付账款账龄分析表
            </div>
            <div class="ml-2 mr-2 lsp" v-show="codetype == 's4'">
              基础数据表
            </div>
            <div class="ml-2 mr-2 lsp" v-show="codetype == 's5'">
              企业指标表
            </div>
          </div>
          <div v-show="$route.query.flag == 3">
            <span class="mr-1" style="font-size: 14px">修改时间 </span>
            <el-select
              v-model="editTime"
              size="small"
              style="width: 170px"
              @change="editTimeChange"
            >
              <el-option
                v-for="item in editTimeData"
                :key="item.log_id"
                :label="
                  item.modified_at.slice(0, 10) +
                  ' ' +
                  item.modified_at.slice(11, 16)
                "
                :value="item.log_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="mt-3">
          <el-table
            v-show="codetype == 's1'"
            :data="dataList"
            style="color: #333; font-size: 14px"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
          >
            <el-table-column prop="name" label="项目"> </el-table-column>
            <el-table-column
              prop="value1"
              :label="BType == '00' ? '本年金额(元)' : '本期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value1"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="value2"
              :label="BType == '00' ? '上年金额(元)' : '上期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3' || dis"
                  size="mini"
                  v-model="row.value2"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            v-show="codetype == 's2'"
            :data="dataListTwo"
            style="color: #333; font-size: 14px"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
          >
            <el-table-column prop="name" label="项目"></el-table-column>
            <el-table-column
              prop="value1"
              :label="BType == '00' ? '本年金额(元)' : '本期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value1"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="value2"
              :label="BType == '00' ? '上年金额(元)' : '上期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3' || dis"
                  size="mini"
                  v-model="row.value2"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="codetype == 's3'"
            :data="dataListThree"
            style="color: #333; font-size: 14px"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
          >
            <el-table-column prop="name" label="项目"></el-table-column>
            <el-table-column prop="value1.value" label="余额"></el-table-column>
            <el-table-column prop="value2.value" label="1年以内金额(元)">
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value2.value"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="value3.value" label="1年(含)-2年金额(元)">
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value3.value"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
            <el-table-column prop="value4.value" label="二年(含)-三年金额(元)">
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value4.value"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="value5.value" label="三年及以上金额(元)">
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value5.value"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="codetype == 's4'"
            :data="dataListFour"
            style="color: #333; font-size: 14px"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
          >
            <el-table-column prop="name" label="项目"></el-table-column>
            <el-table-column
              prop="value1"
              :label="BType == '00' ? '本年金额(元)' : '本期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value1"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="value2"
              :label="BType == '00' ? '上年金额(元)' : '上期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3' || dis"
                  size="mini"
                  v-model="row.value2"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="codetype == 's5'"
            :data="dataListFive"
            style="color: #333; font-size: 14px"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
          >
            <el-table-column prop="name" label="项目"></el-table-column>
            <el-table-column
              prop="value1"
              :label="BType == '00' ? '本年金额(元)' : '本期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3'"
                  size="mini"
                  v-model="row.value1"
                ></el-input-number>
                <div
                  class="pl-1"
                  v-show="row.before_mod_cur != undefined"
                  style="color: #f2637b; font-size: 12px"
                >
                  变更前：{{ row.before_mod_cur }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="value2"
              :label="BType == '00' ? '上年金额(元)' : '上期金额(元)'"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  @keydown.enter.native="nextFocus($event)"
                  :precision="2"
                  :min="-Infinity"
                  :max="Infinity"
                  :controls="false"
                  style="width: 80%"
                  :disabled="flag == '1' || flag == '3' || dis"
                  size="mini"
                  v-model="row.value2"
                ></el-input-number>
                <div
                  style="
                    padding: 0px;
                    color: #f2637b;
                    font-size: 12px;
                    height: 20px;
                  "
                  v-show="row.before_mod_cur != undefined"
                ></div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="flexBottom">
      <el-button
        @click="back"
        size="small"
        style="width: 120px; font-size: 14px"
        >取 消</el-button
      >
      <el-button
        size="small"
        v-show="flag == '0' || flag == '2'"
        type="primary"
        @click="onSubmit"
        style="width: 120px; font-size: 14px"
        >提 交
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  read_annotation, //辅助表详情
  S_save, //修改上传
  change_logOt,
  change_logEdit,
  echo_annotation,
} from "@/api";
import moment from "moment";
import * as sesstion from "../../store/localStorage";
export default {
  data() {
    return {
      name: sesstion.getLocalData("company").name,
      flag: sesstion.getLocalData("routerFlag").flag,
      codetype: sesstion.getLocalData("routerFlag").annotation_type,
      dateTime: sesstion.getLocalData("routerFlag").report_year,
      BType: sesstion.getLocalData("routerFlag").report_time,
      code: sesstion.getLocalData("routerFlag").report_type,
      company_code: sesstion.getLocalData("company").company_code,
      pageType: [
        {
          value: "授信辅助表",
          key: "s1",
        },
        {
          value: "补充数据表",
          key: "s2",
        },
        {
          value: "应收账款及应付账款账龄分析表",
          key: "s3",
        },
        {
          value: "基础数据表",
          key: "s4",
        },
        {
          value: "企业指标表",
          key: "s5",
        },
      ],

      pageTypeList: [
        {
          key: "00",
          value: "年报",
        },
        {
          key: "01",
          value: "月报",
        },

        // {
        //     key: '001',
        //     value: "一月报"
        // }, {
        //     key: '002',
        //     value: "二月报"
        // }, {
        //     key: '003',
        //     value: "三月报"
        // }, {
        //     key: '004',
        //     value: "四月报"
        // }, {
        //     key: '005',
        //     value: "五月报"
        // }, {
        //     key: '006',
        //     value: "六月报"
        // }, {
        //     key: '007',
        //     value: "七月报"
        // }, {
        //     key: '008',
        //     value: "八月报"
        // }, {
        //     key: '009',
        //     value: "九月报"
        // }, {
        //     key: '010',
        //     value: "十月报"
        // }, {
        //     key: '011',
        //     value: "十一月报"
        // }, {
        //     key: '012',
        //     value: "十二月报"
        // }
      ],

      dis: true,

      options: [
        {
          key: "00",
          value: "合并报表",
        },
        {
          key: "01",
          value: "本部报表",
        },
      ],
      editTimeData: [],
      editTime: "",
      // 授信辅助表
      dataList: [
        {
          name: "他行授信额度",
          value1: "",
          value2: "",
          code: "dw001",
        },
        {
          name: "发债额度",
          value1: "",
          value2: "",
          code: "dw002",
        },
        {
          name: "或有负债",
          value1: "",
          value2: "",
          code: "dw003",
        },
        {
          name: "无法变现的长期待摊费用",
          value1: "",
          value2: "",
          code: "dw004",
        },
        {
          name: "递延资产及其他",
          value1: "",
          value2: "",
          code: "dw005",
        },
        {
          name: "待处理流动资产损益",
          value1: "",
          value2: "",
          coed: "dw006",
        },
        {
          name: "待扣除土地使用权、采矿权和BTO资产后的无形资产",
          value1: "",
          value2: "",
          code: "dw007",
        },
        {
          name: "固定资产清理",
          value1: "",
          value2: "",
          code: "dw008",
        },
      ],
      // 补充数据表
      dataListTwo: [
        {
          name: "营业收入净额",
          value1: "",
          value2: "",
          code: "dwbcsjb1",
        },
        {
          name: "主营业务收入净值",
          value1: "",
          value2: "",
          code: "dwbcsjb2",
        },
        {
          name: "主营业务成本",
          value1: "",
          value2: "",
          code: "dwbcsjb3",
        },
        {
          name: "主营业务税金及附加",
          value1: "",
          value2: "",
          code: "dwbcsjb4",
        },
        {
          name: "主营业务利润",
          value1: "",
          value2: "",
          code: "dwbcsjb5",
        },
        {
          name: "利润表中：利息费用支出",
          value1: "",
          value2: "",
          code: "dwbcsjb6",
        },
        {
          name: "全部利息费用支出",
          value1: "",
          value2: "",
          code: "dwbcsjb7",
        },
        {
          name: "产量",
          value1: "",
          value2: "",
          code: "dwbcsjb8",
        },
        {
          name: "产值",
          value1: "",
          value2: "",
          code: "dwbcsjb9",
        },
        {
          name: "水费",
          value1: "",
          value2: "",
          code: "dwbcsjb10",
        },
        {
          name: "电费",
          value1: "",
          value2: "",
          code: "dwbcsjb11",
        },
        {
          name: "销量",
          value1: "",
          value2: "",
          code: "dwbcsjb12",
        },
        {
          name: "销值",
          value1: "",
          value2: "",
          code: "dwbcsjb13",
        },
        {
          name: "或有负债",
          value1: "",
          value2: "",
          code: "dwbcsjb14",
        },
        {
          name: "本年利润分配",
          value1: "",
          value2: "",
          code: "dwbcsjb15",
        },
        {
          name: "一年内到期的长期负债",
          value1: "",
          value2: "",
          code: "dwbcsjb16",
        },
      ],
      dataListThree: [
        {
          name: "应收账款",
          value1: {
            value: "",
          },
          value2: {
            code: "yszkjyf1",
            value: "",
          },
          value3: {
            code: "yszkjyf2",
            value: "",
          },
          value4: {
            code: "yszkjyf3",
            value: "",
          },
          value5: {
            code: "yszkjyf4",
            value: "",
          },
        },
        {
          name: "应付账款",
          value1: {
            value: "",
          },
          value2: {
            code: "yszkjyf5",
            value: "",
          },
          value3: {
            code: "yszkjyf6",
            value: "",
          },
          value4: {
            code: "yszkjyf7",
            value: "",
          },
          value5: {
            code: "yszkjyf8",
            value: "",
          },
        },
      ],
      dataListFour: [
        {
          name: "一年内到期的长期借款",
          value1: "",
          value2: "",
          code: "jcsjb28",
        },
        {
          name: "短期借款（含敞口部分）",
          value1: "",
          value2: "",
          code: "jcsjb29",
        },
        {
          name: "本行短期借款（含敞口部分）",
          value1: "",
          value2: "",
          code: "jcsjb30",
        },
        {
          name: "本行长期借款",
          value1: "",
          value2: "",
          code: "jcsjb31",
        },
        {
          name: "销售收入净额",
          value1: "",
          value2: "",
          code: "jcsjb32",
        },
        {
          name: "存入客户账户的销售回笼款",
          value1: "",
          value2: "",
          code: "jcsjb33",
        },
        {
          name: "存入客户主要关联账户的销售回笼款",
          value1: "",
          value2: "",
          code: "jcsjb34",
        },
        {
          name: "营业税或增值税（营业税金及附加）",
          value1: "",
          value2: "",
          code: "jcsjb35",
        },
        {
          name: "其他渠道提供的营运资金含（含信托等）",
          value1: "",
          value2: "",
          code: "jcsjb36",
        },
        {
          name: "预测下个会计期间销售收入（流动资金授信时必须填写）",
          value1: "",
          value2: "",
          code: "jcsjb37",
        },
        {
          name: "拟授信额度中银行承兑汇票和信用证敞口之和",
          value1: "",
          value2: "",
          code: "jcsjb38",
        },
        {
          name: "申请流动资金和固定资产混合贷款时输入申请的流动资金贷款额度",
          value1: "",
          value2: "",
          code: "jcsjb39",
        },
        {
          name: "调减项目",
          value1: "",
          value2: "",
          code: "jcsjb40",
        },
        {
          name: "或有风险负债调减",
          value1: "",
          value2: "",
          code: "jcsjb41",
        },
        {
          name: "面临重大诉讼调减",
          value1: "",
          value2: "",
          code: "jcsjb42",
        },
        {
          name: "关联企业经营风险调减",
          value1: "",
          value2: "",
          code: "jcsjb43",
        },
        {
          name: "经营环境不利变化调减",
          value1: "",
          value2: "",
          code: "jcsjb44",
        },
        {
          name: "其他情形调减D5(根据企业实际必填项）",
          value1: "",
          value2: "",
          code: "jcsjb45",
        },
      ],
      dataListFive: [
        {
          name: "每股收益",
          value1: "",
          value2: "",
          code: "zb34",
        },
        {
          name: "市盈率",
          value1: "",
          value2: "",
          code: "zb35",
        },
        {
          name: "股利增长率",
          value1: "",
          value2: "",
          code: "zb36",
        },
        {
          name: "每股现金流量",
          value1: "",
          value2: "",
          code: "zb37",
        },
      ],
      inputDoms: "",
    };
  },
  watch: {},
  created() {
    if (this.BType == "00") {
      this.BType = sesstion.getLocalData("routerFlag").report_time;
    } else {
      this.dateTime =
        sesstion.getLocalData("routerFlag").report_year +
        "-" +
        sesstion.getLocalData("routerFlag").report_time.substring(1, 3);
      this.BType = "01";
    }

    this.$nextTick(() => {
      this.initInputDOM();
    });
  },
  mounted() {
    if (this.$route.query.flag == "0") {
      this.period();
    } else if (this.$route.query.flag == "1" || this.$route.query.flag == "2") {
      this.getread();
    } else {
      let data = sesstion.getLocalData("F_data");
      this.editTimeData = data.change_log;
      this.editTime = data.change_log[0].log_id;
      this.editTimeChange(this.editTime);
    }
  },
  methods: {
    period() {
      let company = sesstion.getLocalData("routerFlag");
      let param = {
        annotation_type: company.annotation_type,
        company_code: company.company_code,
        report_type: company.report_type,
        report_time: company.report_time,
        report_year: company.report_year,
      };
      echo_annotation(param).then((res) => {
        if (res.data.Status == 0) {
          this.dis = false;
          this.$message.warning(res.data.Message);
        } else {
          this.dis = true;
          if (res.data.Data.annotation_type == "s1") {
            this.dataList = res.data.Data.array;
          } else if (res.data.Data.annotation_type == "s2") {
            this.dataListTwo = res.data.Data.array;
          } else if (res.data.Data.annotation_type == "s3") {
            this.dataListThree = res.data.Data.array;
          } else if (res.data.Data.annotation_type == "s4") {
            this.dataListFour = res.data.Data.array;
          } else if (res.data.Data.annotation_type == "s5") {
            this.dataListFive = res.data.Data.array;
          }
        }
      });
    },
    getread() {
      let company = sesstion.getLocalData("routerFlag");
      let param = {
        ...company,
      };
      // 获取详情
      // if (company.flag !== '0') {
      read_annotation(param).then((res) => {
        if (res.data.Data.annotation_type == "s1") {
          this.dataList = res.data.Data.array;
        } else if (res.data.Data.annotation_type == "s2") {
          this.dataListTwo = res.data.Data.array;
        } else if (res.data.Data.annotation_type == "s3") {
          this.dataListThree = res.data.Data.array;
        } else if (res.data.Data.annotation_type == "s4") {
          this.dataListFour = res.data.Data.array;
        } else if (res.data.Data.annotation_type == "s5") {
          this.dataListFive = res.data.Data.array;
        }
      });
      // }
    },
    editTimeChange(value) {
      // console.log(value);
      let data = sesstion.getLocalData("routerFlag");
      let param = {
        log_id: this.editTime,
        company_code: data.company_code,
        report_year: data.report_year,
        report_time: data.report_time,
        report_type: data.report_type,
        annotation_type: this.codetype,
      };
      change_logEdit(param).then((res) => {
        if (this.codetype == "s1") {
          this.dataList = res.data.change_log[0].formated.array;
        } else if (this.codetype == "s2") {
          this.dataListTwo = res.data.change_log[0].formated.array;
        } else if (this.codetype == "s3") {
          this.dataListThree = res.data.change_log[0].formated.array;
        } else if (this.codetype == "s4") {
          this.dataListFour = res.data.change_log[0].formated.array;
        } else if (this.codetype == "s5") {
          this.dataListFive = res.data.change_log[0].formated.array;
        }
      });
    },
    onSubmit() {
      if (this.codetype == "s1") {
        this.postValue("s1");
        return;
      }
      if (this.codetype == "s2") {
        this.postValue("s2");
        return;
      }
      if (this.codetype == "s3") {
        this.postValue("s3");
        return;
      }
      if (this.codetype == "s4") {
        this.postValue("s4");
        return;
      }
      if (this.codetype == "s5") {
        this.postValue("s5");
        return;
      }
    },
    postValue(type) {
      if (this.BType == "00") {
        var company = {
          report_year: moment(this.dateTime).format("YYYY"),
          report_time: this.BType,
          report_type: this.code,
          company_code: this.company_code,
          annotation_type: this.codetype,
        };
      } else {
        var company = {
          report_year: moment(this.dateTime).format("YYYY"),
          report_time: sesstion.getLocalData("routerFlag").report_time,
          report_type: this.code,
          company_code: this.company_code,
          annotation_type: this.codetype,
        };
      }
      if (type == "s1") {
        company.report = this.dataList;
      }
      if (type == "s2") {
        company.report = this.dataListTwo;
      }
      if (type == "s3") {
        company.report = this.dataListThree;
      }
      if (type == "s4") {
        company.report = this.dataListFour;
      }
      if (type == "s5") {
        company.report = this.dataListFive;
      }
      S_save(company).then((res) => {
        // console.log(res);
        if (res.data.Status == 1) {
          this.$message({
            message: res.data.Message,
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/reportManagement");
          }, 1000);
        } else {
          return this.$message.error(res.data.Message);
        }
      });
    },
    back() {
      this.$router.push("/reportManagement");
    },
    initInputDOM() {
      // 获取id为table-form下的所有input 框
      const inputDoms = document.querySelectorAll(".el-input__inner");
      // 遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute("data-index", index);
      });
      this.inputDoms = inputDoms;
      // console.log(this.inputDoms);
    },

    // 回车事件
    nextFocus(event) {
      // console.log(event);
      const index = event.target.getAttribute("data-index");
      // console.log(index);
      const nextIndex = parseInt(index) + 2;
      // console.log(nextIndex);
      const length = this.inputDoms.length;
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus();
      } else {
        this.inputDoms[0].focus();
      }
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  /deep/.el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }

  /deep/.el-input-number .el-input__inner {
    text-align: left !important;
  }

  padding-bottom: 50px;

  #pragmatic {
    margin-top: 40px;
    padding: 40px 60px 60px 60px;
    background: #ebf2f8;

    .ManageTop,
    .ManageBottom {
      background: #fff;
      border-radius: 4px;
      padding: 25px;
    }

    .ManageTop {
      // height: 200px;
      .ManageTopInput {
        display: flex;
        flex-wrap: wrap;
      }

      .title {
        font-size: 18px;
        font-weight: 550;
      }

      .TitleText {
        width: 100px;
        font-size: 16px;
        color: #000;
        text-align: right;
        margin-right: 10px;
      }

      .grid-content {
        color: #333;
        font-size: 16px;
      }

      .box {
        display: flex;
      }
    }

    .ManageBottom {
      margin-top: 25px;

      .sp {
        width: 4px;
        height: 20px;
        background: #007bff;
        border-radius: 2px;
        margin-left: 5px;
      }

      .lsp {
        font-size: 18px;
        font-weight: 550;
        color: #515a6e;
      }
    }
  }

  .flexBottom {
    background: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: 70px;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }
}
</style>